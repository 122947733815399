.pic-select {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
  margin: 0.2rem;
  transition: 300ms;
  border: none;
  border: 0.2rem solid rgba($highlight, 0);
}

.pic-select:hover {
  transition: 300ms;
  border: 0.2rem solid rgba($highlight, 0.5);
}

.pic-select-active {
  border: 0.2rem solid $highlight;
  background-color: $highlight;
  -webkit-box-shadow: inset 0 0 15px #2c364a;
  -moz-box-shadow: inset 0 0 15px #2c364a;
  box-shadow: inset 0 0 15px #2c364a;
}

.modal {
  --bs-modal-zindex: 1054;
  --bs-modal-width: 1000px!important;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: white!important;
  --bs-modal-bg: #1E1E24!important;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.load-mote-btn {
  background-color: $lighter;
  border-radius: 0.25rem;
  cursor: pointer;
}

.load-mote-btn:hover {
  background-color: lighten($lighter, 8);
}

.account-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 2px 5px;
  border: 2px solid #cebd92;
}

.profile-dropdown {
  background-color: rgba($color: #000000, $alpha: 0)!important;
  border: none!important;
  padding: 0!important;
}

.account-dropdown {
  color: white!important;
  background-color: $light!important;
}

.sign-in-btn {
  cursor: pointer;
}
.char-selector {
  height: 30vh;
  overflow: scroll;
}
.skill-slots {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.skill-slot {
  flex: 1;
  margin: 0 10px;
  text-align: center;
}

.build-dropdown {
  position: relative; /* This allows the menu to be positioned relative to the dropdown container */
  display: inline-block;
}

.build-dropdown-toggle {
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
  background-color: $light;
  border: 0.2rem solid $mid;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.build-dropdown-toggle:hover {
  background-color: #3e3e40;
}

.build-dropdown-menu {
  position: absolute; /* Ensures the dropdown menu floats over other content */
  top: 100%; /* Position the menu just below the dropdown toggle */
  left: 0;
  z-index: 1000; /* High z-index to make sure it appears on top */
  width: 100%; /* Match the width of the dropdown container */
  background-color: #1c1c1e; /* Modern dark background */
  border: 1px solid #3e3e40;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow for a modern "floating" effect */
  margin-top: 5px;
  padding: 5px 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(-10px); /* Hide with smooth animation initially */
  pointer-events: none; /* Prevent clicks when hidden */
}

.build-dropdown-open .build-dropdown-menu {
  opacity: 1; /* Show the dropdown */
  transform: translateY(0); /* Slide down smoothly */
  pointer-events: auto;
}

.build-dropdown-item {
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.build-dropdown-item:hover {
  background-color: #525253; /* Slight hover effect */
  border-radius: 4px;
}

.build-skill-img {
  width: 5rem;
  height: 5rem;
}