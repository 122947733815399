.char-container {
  max-width: 1350px!important;
}

.image-container {
  position: relative;
  width: 5.5rem; /* Adjust to your desired width */
  height: 18.6rem; /* Adjust to your desired height */
  // overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.side-char-list {
  background-color: darken($light, 3.5);
  border-radius: 0.5rem;
}

.background-layer {
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  mask-image: url('../assets//img/mask.png');
  mask-size: cover;
  mask-position: center;
}

.image-container .character-layer {
  z-index: 2;
  top: 2.5%;
  mask-image: url('../assets//img/mask2.png');
  mask-size: contain;
  mask-position: center;
}

.frame-layer {
  z-index: 3;
}

.image-container .sprite-layer {
  position: absolute;
  z-index: 4;
  width: 8rem;
  height: 10rem;
  top: 71%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.char-list_name {
  position: absolute;
  bottom: 2%;
  width: 122%;
  line-height: 0.8rem;
  padding: 0.25rem 0;
  text-align: center;
  border: solid 2px;
  border-radius: 0.5rem;
  // color: white;
  font-size: 1rem;
  font-weight: bold;
  z-index: 5;
}
.border-Defender {
  border-color: #45B34E;
}


.image-container .class-icon {
  z-index: 6;
  width: 1.6rem;
  height: 1.6rem;
  top: 4.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
}

.class-icon-row {
  position: absolute;
  z-index: 2;
  width: 1.6rem;
  height: 1.6rem;
  margin-top: 2rem;
  margin-left: 0.2rem;
}

.profile-img-div {
  position: relative;
  // overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 0.25rem;
  // border: solid 2px $black-bg;
  // background-color: $blue;
  background-size: cover;
  background-position: center;
  height: 20rem;
  z-index: 0;
  overflow: hidden;
}

.profile-img {
  margin-top: -1rem;
  width: 20rem;
  height: 27.4rem;
}

.char-name-div {
  background-color: $bege;
  color: $txt;
}

.char-name {
  padding-top: 0.3rem;
}

.skill-tree-stat {
  background-color: $bege;
  color: $txt;
  line-height: 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: bolder;
  padding: 0.1rem 0.75rem;
}

.skill-tree-stat-drk {
  background-color: rgba($lighter,0.7)!important;
  color: white;
  line-height: 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: bolder;
  padding: 0.1rem 0.75rem;
}


.skill-tree-stat_value {
  background-color: $light;
  line-height: 1rem;
  color: $heal;
  height: 85%;
  border-radius: 5rem;
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif!important;
}

.hr {
  margin: 0.25rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .5;
}

.stat-item-bg-w {
  width: 50%;
  @media only screen and (max-width: $md) {
    width: 100%;
  }
}

.stat-item-bg {
  background-color: $lighter;
  border-radius: 5rem;
  margin: 0.25rem;
  font-size: 1rem;
  padding: 0;
  line-height: 0;
  font-weight: bold;
  @media only screen and (max-width: $md) {
    margin: 0.15rem;
  }
}

.stat-label-txt {
  font-family: "PT Serif", serif;
  font-weight: 600;
  font-size: 0.8rem;
}

.stat-icon-bg {
  background-color: $dark;
  border-radius: 5rem 0 0 5rem;
  padding-left: 0.5rem;
  padding-right: 0.25rem;
  margin-right: 0.5rem;
  @media only screen and (max-width: $md) {
    margin-right: 0.2rem;
  }
}

.stat-icon {
  width: 1.5rem;
}

.faction-icon {
  width: 2rem;
  -webkit-filter: drop-shadow(1px 1px 1px #222);
  filter: drop-shadow(1px 1px 1px #222);
}
.faction-img {
  width: 3rem;
  position: absolute;
  -webkit-filter: drop-shadow(1px 1px 1px #222);
  filter: drop-shadow(1px 1px 1px #222);
  margin-top: -0.15rem;
  margin-left: -0.25rem;
}
.faction-img-effect {
  width: 2.5rem;
  margin-top: 0;
}
.faction-title {
  font-size: 1.1rem;
  font-weight: bold;
  font-style: italic;
  margin-left: 2.75rem;
  padding-right: 0.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  line-height: 1.2;
}
.faction-title-effect {
  font-size: 1rem;
  margin-left: 2.4rem;
}
.faction-title-div {
  border-radius: 0.25rem;
  border: 2px solid rgba($gold, 0);
  cursor: pointer;
  transition: 200ms;
  -webkit-box-shadow: 2px 2px 1px 0px rgba(0,0,0,0.6);
  -moz-box-shadow: 2px 2px 1px 0px rgba(0,0,0,0.6);
  box-shadow: 2px 2px 1px 0px rgba(0,0,0,0.6);
}
.faction-title-div:hover {
  border: 2px solid rgba($gold, 1);
  transition: 200ms;
}
.the_union-bg {background-color: #415B75;}
.fortitude-bg {background-color: #9A6250;}
.drifter-bg {background-color: #705B41;}
.aggression-bg {background-color: #A16755;}
.papal_states-bg {background-color: #dad091;}
.sword_of_convallaria-bg {background-color: #5F8678;}
.alacrity-bg {background-color: #778FB0;}
.vlder-bg {background-color: #689081;}
.discipline-bg {background-color: #778FB0;}
.iria-bg {background-color: #CB8976;}

.char-detail-bg {
  position: relative;
  background-color: $light;
  border-radius: 0.2rem;
  border: solid 1px rgba($black-bg, 0.7) ;
  border-radius: 0.25rem;
}



.black-label-div {
  background-color: $mid;
  text-transform: uppercase;
  color: white;
  font-family: "PT Sans", sans-serif;
  font-weight: bolder;
  font-style: italic;
  text-align: center;
  font-size: 1.3rem;
  margin-top: -5px;
  z-index: 1;
}

.skill-detail-bg {
  background-color: $light;
  border-radius: 0.25rem;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
}

.skill-detail-div {
  border: 1px solid $gold;
  border-radius: 0.25rem;
}

.skill-detail-div_new {
  border-radius: 0.5rem;
  cursor: pointer;
  transition: 500ms;
  border: 2px solid rgba($color: #000000, $alpha: 0);
  margin: 0.1rem 0;
}

.new-skill-tree-1 {
  background-image: linear-gradient(to right, rgba(255,0,0,0), $light);
  transition: 500ms;
}
.new-skill-tree-2 {
  background-image: linear-gradient(to left, rgba(255,0,0,0), $light);
  transition: 500ms;
}

.new-skill-tree-1:hover, .new-skill-tree-1-active {
  background-image: linear-gradient(to right, rgba(255,0,0,0), $lighter);
  transition: 500ms;
}
.new-skill-tree-2:hover, .new-skill-tree-2-active {
  background-image: linear-gradient(to left, rgba(255,0,0,0), $lighter);
  transition: 500ms;
}


.skill-detail-rec {
  border: 2px solid $highlight!important;
}
.skill-detail-rec2 {
  border: 2px solid $golden;
}
.rec-label-color {
  font-size: 1.5rem;
  line-height: 1.25rem;
}
.bg-op {
  color: $highlight!important;
  
}
.bg-rec {
  color: $golden!important;
}
.rec-label {
  font-family: "Roboto", sans-serif!important;
  margin-right: 0.75rem;
}


.role-img {
  width: 2rem;
  height: 2rem;
}

.stat-placement {
  color: $highlight;
  font-size: 0.9rem;
  text-align: end;
  margin-right: 0.5rem;
  font-family: "PT Serif", serif;
  @media only screen and (max-width: $md) {
    margin-right: 0.25rem;
    font-size: 0.7rem;
  }
}

.stat-value-txt {
  font-weight: normal;
}

.-stand {
  font-family: "PT Serif", serif;
  font-size: 1rem;
}

.new-trait-img-container {
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
}


.trait-img-container {
  position: absolute;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: 0.5rem;
}

.trait-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background {
  z-index: 1;
}
.opacity-8 {
  opacity: 0.8;
}

.foreground {
  z-index: 2;
}

.trait-div {
  height: 5rem;
}

.trait-title {
  background-image: linear-gradient(to right, $lighter, $lighter,rgba(255,0,0,0));
  border-radius: 1.5rem;
  width: 100%;
}

.trait-title-txt {
  margin-left: 5.5rem;
  text-shadow: 5px 5px 5px rgba($black-bg, 0.7);
  font-family: "PT Sans", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 1.3rem;
}

.tarot-title-txt {
  margin-left: 4.25rem;
  text-shadow: 5px 5px 5px rgba($black-bg, 0.7);
  font-family: "PT Sans", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 1.3rem;
}

.trait-title-txt_new {
  text-shadow: 5px 5px 5px rgba($black-bg, 0.7);
  font-family: "PT Sans", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.skilltree-title-txt {
  text-shadow: 5px 5px 5px rgba($black-bg, 0.7);
  font-family: "PT Sans", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 1.2rem;
}

.skill-category {
  font-size: 0.8rem;
  background-color: $gold;
  padding: 0rem 0.5rem;
  color: black;
  font-weight: 900;
  border-radius: 0 0 1.5rem 0;
  padding: 0.1rem 1rem;
  padding-right: 1.4rem;
}
.skill-category-rec {
  background-color: $highlight;
}
.skill-category-rec2 {
  background-color: $golden;
}
.skill-category-div {
  margin-top: -0.25rem;
}

.skill-range-div {
  margin: 0.2rem;
  border: 1px solid $lighter;
  border-radius: 0.2rem;
  padding: 0.5rem;
}

.range-img {
  width: 5rem;
  height: 5rem;
}

.skill-type-div {
  background-color: $lighter;
  border-radius: 0.2rem;
  text-align: center;
  width: 50%;
  margin: 0 0.5rem;
  font-size: 0.9rem;
  line-height: 1rem;
  padding: 0.1rem 0;
}

.skill-range-hr {
  margin: 0.2rem 0;
  color: $gold;
  opacity: .5;
}

.skill-range-value {
  font-size: 0.8rem;
}

.skill-mana-icon {
  width: 1.25rem;
  margin-bottom: 0.15rem;
}

.skill-mana {
  margin-top: 0.2rem;
  margin-bottom: -2rem;
}

.skill-mana-item {
  background-color: $lighter;
  padding: 0rem 0.25rem;
  border-radius: 0.25rem;
}

.skilltree-mobile-container{
  @media only screen and (max-width: $md) {
    display: flex;
  }
}
.mobile-border{
  @media only screen and (max-width: $md) {
    border: 3px solid #dacfb4;
    padding-left: 1.25rem;
    margin: 0.25rem 0;
    margin-left: -0.75rem;
    border-radius: 0.5rem;
  }
}
.skilltree-div{
  flex-wrap: nowrap;
}
.skilltree-div-mobile {
  flex-wrap: wrap;
  @media only screen and (min-width: $md) {
    flex-wrap: nowrap;
  }
}
.skilltree-skill {
  width: 48%;
}
.skilltree-skill-mobile {
  width: 100%;
  @media only screen and (min-width: $md) {
    width: 48%;
  }
}
.tree-rank {
  width:50px;
  height:50px;
  position: relative;
  display: inline-block;
  z-index: 2!important;
  margin: 2.5rem 1rem;
  @media only screen and (max-width: $md) {
    width: 40px;
    height: 40px;
    margin: 0;
    margin-left: -0.25rem;
  } 
}

.tree-rank_new {
  width:40px;
  height:40px;
  position: relative;
  display: inline-block;
  z-index: 2!important;
  margin: 1.5rem 1rem;
  @media only screen and (max-width: $md) {
    width: 30px;
    height: 30px;
    margin: 1.5rem 1rem;
  } 
}

.tree-rank:before {
  position: absolute;
  display: block;
  content: "";
  width:50px;
  height:50px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #e3dacf;
  border: 0.25rem solid #6284a8;
  border-radius: 0.5rem;
  @media only screen and (max-width: $md) {
    width: 40px;
    height: 40px;
    border: 0.20rem solid #6284a8;
  } 
}

.tree-rank_new:before {
  position: absolute;
  display: block;
  content: "";
  width:40px;
  height:40px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #e3dacf;
  border: 0.25rem solid #6284a8;
  border-radius: 0.5rem;
  @media only screen and (max-width: $md) {
    width: 40px;
    height: 40px;
    border: 0.20rem solid #6284a8;
    margin-left: -0.2rem;
  } 
}

.tree-rank__txt {
  line-height: 50px;
  text-align: center;
  position: relative;
  display: inline-block;
  font-size: 1.2rem;
  width: 100%;
  color: $txt;
  font-weight: 700;
  @media only screen and (max-width: $md) {
    font-size: 1rem;
    line-height: 40px;
    margin-right: 0.15rem;
  } 
}

.tree-rank__txt_new {
  font-size: 1rem;
  line-height: 40px;
  margin-left: 0.15rem;
  @media only screen and (max-width: $md) {
    font-size: 1rem;
    line-height: 40px;
    margin-left: 0;
  } 
}

.skilltree-label {
  font-size: 1.3rem;
  font-family: "PT Serif", serif;
  border: 1px solid $gold;
  border-right: none;
  border-left: none;
  color: $gold;
  width: 50%;
}

.skilltree-label-right {
  background-image: linear-gradient(to right, $light, rgba(255,0,0,0));
  border-radius:2rem 0 0 2rem;
}

.skilltree-label-left {
  background-image: linear-gradient(to right, rgba(255,0,0,0), $light);
  border-radius:0  2rem 2rem 0;
  margin-right: 0.25rem;
}


.tree-rank__vl {
  border-left: 3px solid #dacfb4;
  height: 550px;
  position: absolute;
  top: -80%;
  left: 48%;
  z-index: -1!important;
}

.tree-rank__vl_new {
  height: 180px;
}

.tree-rank__vl-last {
  height: 250px!important;
}

.tree-rank__vl-last_new {
  height: 130px!important;
}

.show-rec-btn {
  padding: 0.25rem 5rem!important;
  border-radius: 0.25rem!important;
  border: 1px solid $lighter!important;
  background-color: $light!important;
  cursor: pointer;
  font-family: "PT Sans", sans-serif!important;
  text-transform: uppercase;
  font-weight: bolder;
  font-style: italic;
  transition: 200ms;
}

.show-rec-btn-active {
  background-color: rgba(255,0,0,0)!important;
  border: 1px solid $highlight!important;
}

.show-rec-btn:hover {
  background-color: rgba($light,0.6)!important;
  border: 1px solid $highlight!important;
  transition: 200ms;
}

.char-row {
  background: linear-gradient(to left,
    rgba(black,0),
    rgba($highlight,0.6),
  );
  background-size: cover!important;
  height: 4rem;
  overflow: hidden;
  border: 1px solid $light;
  border-radius: 0.2rem;
  transition: 200ms;
}

.char-row:hover {
  border-color: $highlight;
  transition: 200ms;
}

.char-row-img {
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.char-row-title {
  font-family: Ardela Edge, sans-serif;
  font-style: italic;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-top: .1rem;
  text-shadow: 3px 3px 4px rgba(0,0,0,.9);
  height: 2rem;
  color: white;
  text-decoration: none!important;
}
.char-cut-div {
  position: relative;
  overflow: hidden;
  height: 3.5rem;
}
.char-cut-img {
  opacity: 0.7;
  right: 0;
  overflow: hidden;
  width: 22rem;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.char-row-sprite {
  width: 5rem;
  height: 6.5rem;
  position:relative;
  right: 0;
  margin-bottom: -1.1rem;
}

.char-face-container {
  position: relative;
  width: 4.5rem;
  height: 4.5rem;
  opacity: 1!important;
  overflow: hidden;
}

.char-face {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1!important;
}

.char-face-img-container {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  overflow: hidden;
  opacity: 1!important;
}

.char-face-img {
  position: absolute;
  width: 135%;
  right: -5.8%;
  bottom: 0;
  mask-image: url('../assets/img/face_bg_mask.png');
  mask-size: 80%;
  mask-position: bottom right; /* Offset the mask position to match the image's new position */
  mask-origin: content-box; /* Ensure the mask aligns with the content box */
  opacity: 1!important;
}

.char-face-border {
  z-index: 3;
}

.art-img {
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
.art-img-full {
  width: auto;
  max-height: 30rem;
}
.char-bio {
  background-color: $bege;
  color: $txt;
  padding: 0.25rem;
  border-radius: 0.25rem;

}

.pixel-bio {
  float: left;
  margin: 0;
  width: 8rem;
  height: auto;
  position: absolute;
}

.black-bg {
  background-color: rgba($mid, 0.7);
  border-radius: 0.5rem;
}

.skill-modal {
  background-color: $light!important;
  color: white!important;
  border-color:  $light!important;
}

.modal-content, .modal-dialog {
  background-color: $light!important;
  border: none!important;
  padding: 0!important;
  border-color:  $light!important;
  color: white!important;
}

.btn-close {
  --bs-btn-close-color: white!important;
}

.art-nav {
  color: white!important;
}

.art-bg {
  background-color: $light;
  border-radius: 0.25rem;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
}

.art-active {
  background-color: $lighter;
}

.sources-bg {
  background-color: rgba($light, 0.5);
  border-radius: 0.25rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 0.25rem;
  max-width: 100%;
}
.source-txt{
  text-transform: uppercase;
  font-style: italic;
  font-size: 0.9rem;
}

.gear-rec-label {
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "PT Sans", sans-serif;
  font-style: italic;
  text-transform: uppercase;
  transition: 200ms;
  @media only screen and (max-width: $md) {
    font-size: 1rem;
  }
}

.gear-rec-bg {
  background-color: $light;
  border-radius: 0.25rem;
  padding: 0.25rem;
  margin: 2.5rem 0;
  cursor: pointer;
  transition: 200ms;
}

.gear-rec-bg:hover {
  background-color: lighten($light, 8);
  transition: 200ms;
}

.gear-rec-icon {
  width: 4.5rem;
  height: 4.5rem;
  @media only screen and (max-width: $md) {
    width: 4rem;
    height: 4rem;
  }
}

.cv-div {
 text-align: end;
}

.bio-title {
  margin-top: 4.8rem;
}

.helmet-char-name {
  text-transform: capitalize;
}

.nostalgia-icon {
  width: 1rem!important;
  height: 1rem!important;
  right: 0;
  margin: 0.2rem;
  margin-left: 0.1rem;
}