.menu-dropdown {
  color: inherit!important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  border: inherit!important;
  border-radius: inherit!important;
  background-color: none!important;
}

.nav-link-container {
  width: 2.5rem;
  height: 2.5rem;
}
.menu-navbar{
  padding: 0!important;
}

.menu-icon {
  width: 2.3rem;
  height: 2.3rem;
  position: absolute;
  background-color: $menu-blue;
  border-radius: 2rem;
  padding: 0.25rem;
  border: 2px solid $menu-bege;
  top: 0.5rem;
  z-index: 1;
  @media only screen and (max-width: $lg) {
    top: auto;
    width: 2rem;
    height: 2rem;
  }
}

.header-nav {
  background-color: #424755;
}

.nav-link {
  padding: 0.25rem 0.25rem!important;
  margin: 0.5rem 0.25rem!important;
  font-size: 1.1rem!important;
}

.nl-border {
  border-right: 1px solid $lighter!important;
}

.header-link {
  padding: 0.15rem 0.5rem!important;
  padding-left: 2.75rem!important;
  border-radius: 0.5rem!important;
  transition: 100ms;
}
.header-link-home {
  padding-left: 0.5rem!important;
  @media only screen and (max-width: $lg) {
    padding-left: 2.75rem!important;
  }
}
.header-link:hover, .header-link-active {
  background-color: $menu-blue!important;
  transition: 100ms;
}

.dropdown-toggle {
  margin: 0!important;
  padding: 0!important;
  color: white!important;
}

.search-box{
  position: relative;
}

.search-input {
  padding: 0.5rem;
  height: 35px;
  background: $menu-blue;
  border: 3px solid $menu-bege;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  color: white;
  outline: none;
  width: -webkit-fill-available;
}

.search-icon{
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(-50%,-50%);
  font-size: 1rem;
  color: #f6f3d7;
  transition: .2s;
}


.footer {
  background-color: $darker;
  width: 100%;
  position: relative;
  clear: both;
}

.footer-img {
  width: 3.5rem;
}
.footer-btn {
  background-color: $menu-blue!important;
  padding: 0.25rem 0.5rem!important;
}

.header-cool {
  // background-image: url('../assets/img/stage03.webp');
  // background-size: cover;
  // background-position: center;
  position: relative;
  overflow: hidden;
  height: 12rem;
  @media (max-width: 767px) {
    height: 4rem;
  }
}

.header-cool-bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.overlay-dark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.discord-img {
  cursor: pointer;
  width: 12rem;
  height: 2.5rem;
}