.effect-txt {
  font-family: "Roboto", sans-serif!important;
}

.highlight {
  color: $physical;
}

.tag-color {
  color: $tag;
  cursor: pointer;
}

.tag-txt {
  color: $tag!important;
  cursor: pointer;
}

.tag-txt-blue {
  color: $tag-blue!important;
  cursor: pointer;
}

.physical-color {
  color: $physical!important;
}
.magical-color {
  color: $magical;
}
.heal-color {
  color: $heal;
}

.debuff-color{
  background: -webkit-linear-gradient(#d67d5b, #c76c4a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.buff-color {
  color: #47a531;
}

.effect-tag-tooltip {
  font-size: 1rem!important;
  text-align: left!important;
}