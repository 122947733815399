@import './settings';
@import './char';
@import './filter';
@import './effect';
@import './event';
@import './skills';
@import './gear';
@import './header';
@import './stages';
@import './account';
@import './comments';

.App {
  background-color: $dark;
  padding-top: 4rem;
  color: white;
  min-height: 95vh;
  font-family: "Merriweather", serif;
  // padding-top: 3rem;
  // background-image: url("../assets/img/bg.jpg");
  // background-size: cover;
}

.font-sans {
  font-family: "PT Sans", sans-serif;
}

.c-pointer {
  cursor: pointer;
}

hr {
  margin: 0.25rem 0!important;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25!important;
}

a {
  color: inherit!important;
  text-decoration: none!important;
  text-decoration-line: none!important;
  // text-decoration-thickness: initial;
  // text-decoration-style: initials;
  // text-decoration-color: initial;
}

.custom-row {
  --bs-gutter-x: 0.5rem!important;
}

:root {
  --rt-opacity: 1!important;
}

.tooltip {
  max-width: 600px;
  @media (max-width: 767px) {
    max-width: 100vw;
  }
}
.w-33 {
  width: 33%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.new-container {
  max-width: 1300px!important;
}
.roboto {
  font-family: 'Roboto', sans-serif;
  text-shadow: 2px 2px 6px rgba(0,0,0,0.9);
}

.ardela {
  font-family: 'Ardela Edge', sans-serif;
  font-weight: bolder;
  font-style: italic;
  text-transform: uppercase;
}

.link {
  color: inherit!important;
}

.txt-shadow {
  text-shadow: 2px 2px 6px #000000;
}
.pb-1-mobile {
  @media (max-width: 767px) {
    padding-bottom: 0.25rem;
  }
}

.loading-screen {
  position:absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  background-color: rgba(#1E1E24, 0.7);
  margin: auto;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-main {
  padding-top: 4.5rem;
}

.mb-0{
  margin-bottom: 0rem!important;
}
.mb-n1 {
  margin-bottom: -1rem!important;
}
.ml-2 {
  margin-left: 0.5rem!important;
}

.font-bolder {
  font-weight: bolder;
}

.font-weight-normal {
  font-weight: normal!important;
}

.Selector{
  color: black!important;
}

.btn-close {
  color: white!important;
}

.progress-bar {
  border-radius: 0.2rem!important;
}

.mr-1 {
  margin-right: 0.25rem!important;
}

.ml-1 {
  margin-left: 0.25rem!important;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.w-80 {
  width: 80%;
}
.w-20 {
  width: 20%;
}