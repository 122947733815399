@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Varela+Round&display=swap');

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$darker: #020912;
$dark: #141D25;
$mid: #0F161A;
$light: #282F38;
$lighter: #4B545C;
$highlight: #66F3F5;

$physical: #ff847b;
$magical: #da90e9;
$heal: #4be036;
$tag: #f6bc55;
$tag-blue: #75c5e7;

$menu-blue: #424755;
$menu-bege:#cebd92;

$bege: #efead8;
$txt: #6a5f42;

$black-bg: #262626;
$gold: #c2b67e;
$golden: #FFDB51;

$Watcher: #F0CB65;
$Defender: #90ee99;
$Destroyer: #E6C4DC;
$Breaker: #FAAC95;
$Seeker: #78B0D8;

.Watcher-color {
  color: $Watcher;
}
.Defender-color {
  color: $Defender;
}
.Destroyer-color {
  color: $Destroyer;
}
.Breaker-color {
  color: $Breaker!important;
}
.Seeker-color {
  color: $Seeker;
}