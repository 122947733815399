.gear-list-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-image: url('../assets/img/face_bg_mask.png');
  mask-size: cover;
  mask-position: center;
  z-index: 10;
}
.gear-title{
  cursor: pointer;
  transition: 200ms;
}
.gear-title:hover {
  color: $highlight;
  transition: 200ms;
}

.gear-img {
  width: 12rem;
}


.gear-list-img {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 99%;
  height: 99%;
  object-fit: cover;
}


.gearpage-title {
  text-shadow: 5px 5px 5px rgba($black-bg, 0.7);
  font-family: "PT Sans", sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;
}

.bg-lighter {
  background-color: rgba($lighter, 0.4);
  padding: 0.25rem;
  border-radius: 0.25rem;
}
.bg-lighter-link {
  background-color: rgba($lighter, 0.4);
  padding: 0.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: 100ms;
  display: inline-block;
}
.bg-lighter-link:hover {
  background-color: rgba($lighter, 0.7);
  transition: 100ms;
  display: inline-block;
}

.gear-desc {
  font-size: 0.85rem;
}

.tarot-gem {
  width: 1.5rem;
}

.tarot-gem-div {
  background-image: linear-gradient(to right, $lighter, $lighter,rgba(255,0,0,0));
  text-align: left!important;
}

.tarot-gem-txt {
  font-size: 0.9rem;
  font-family: "PT Sans", sans-serif;
  font-style: italic;

}